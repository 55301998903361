import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import CustomNavbar from '../Navbar/CustomNavbar';
import { Button } from 'react-bootstrap';
import './accountStyles.css';
import { supabase } from '../../utils/supabase';

const Account = ({session}) => {
  const navigate = useNavigate();
  const email = session?.user?.email || ''
  const [studyPace, setStudyPace] = useState({});
  const studyPaceOptions = [1,2,3,4] // 1,2 or 3 hours per day

  // Fetch study_pace from Supabase on component mount
  useEffect(() => {
    const fetchStudyPace = async () => {
      const { data, error } = await supabase
        .from('Accounts')
        .select('study_pace')
        .eq('user_id', session.user.id)
        .single();

      if (error) {
        console.error('Error fetching study pace:', error.message);
      } else {
        setStudyPace(data?.study_pace || null);
      }
    };

    fetchStudyPace();
  }, [session.user.id]);

  // Handle onChange event for study pace selection
  const handleStudyPaceChange = async (e) => {
    const newStudyPace = parseInt(e.target.value,  10);

    console.log('newStudyPace: ' + newStudyPace);
    console.log(typeof newStudyPace); // Add this line to print the type of newStudyPace
    // Update study_pace in Supabase
    const { error } = await supabase
      .from('Accounts')
      .update({ study_pace: newStudyPace })
      .eq('user_id', session.user.id);

    // if (error) {
    //   console.error('Error updating study pace:', error.message);
    // } else {
    //   console.log('worked.')
    //   setStudyPace(newStudyPace);
    // }
    if (error) {
      console.error('Supabase error:', error);
    } else if (newStudyPace.length === 0) {
      console.log('No rows updated. This may mean the row was not found or the data was already identical.');
    } else {
      console.log('Update successful:', newStudyPace);
      setStudyPace(newStudyPace);
    }
  };

  return (
    <div style={{padding: '20px'}}>
      <CustomNavbar title="Hourandcode" session={session} showProgress={false} onBack={() => navigate(`/courses`)}  />

      <div className="container mt-4 col-12 text-center">
        <h1 className="mb-5">Account</h1>
      </div>
      <div className="container col-12 col-lg-6">
        <div>
          <h4 className="mb-4">Personal Information</h4>
          <label>Email: </label><br/>
          <input
            className="form-control mt-2"
            disabled='true'
            type="email"
            placeholder="Your email"
            value={email}
          />
          <hr />
          <h4 className="mb-4">Preferences</h4>
          <label>Study Pace: </label><br/>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <select
              className="form-control mt-2"
              value={studyPace} // Assuming studyPace is a state variable or prop
              onChange={(e) => handleStudyPaceChange(e)} // Assuming setStudyPace is a state setter function
              style={{ width: '60px', marginRight: '10px' }} // Optional: Add some space between the select and the text
            >
              {studyPaceOptions.map((option, index) => (
               <option key={index} value={option}>{option}</option>
              )
               )}
              {/* <option value="1">1</option> */}
              {/* <option value="2">2</option>
               */}
              {/* <option value="3">3</option> */}
            </select>
            <span>Hour(s) per day</span>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button className="mt-4" variant="primary">Save Preferences</Button>
          </div>
        {/*
          <label>First Name: </label><br/>
          <input
            className="form-control mt-2"
            type="text"
            placeholder="Your First Name"
          />

          <label>Last Name: </label><br/>
          <input
            className="form-control mt-2"
            type="text"
            placeholder="Your Last Name"
          /> */}
          <hr />
          <h4 className="mb-4">Manage subscription</h4>
          {/* <p>Next invoice on <span className="badge badge-dark">Set 12, 2024</span> Suscription end date <span className="badge badge-dark">Sep 12, 2025</span> <span className="badge badge-dark ml-2">Past due</span></p> */}
          <div className="mt-4 pt-4" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <a href className="delete-account-btn">Delete Account</a>
            <a href='https://billing.stripe.com/p/login/test_4gw7v5eROgkNek0144' className="btn btn-dark">Manage Subscription</a>
          </div>
          <hr/>
          {/* <h4 className="mb-4">Invite Link</h4>
          <p>Share a link to Hourandcode with your friends.</p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="text"
              className="form-control"
              value={`https://www.hourandcode.com/invite/${session?.user?.id}`}
              readOnly
              style={{ flexGrow: 1, marginRight: '10px' }}
            />
            <button
              className="btn btn-primary"
              onClick={() => {
                navigator.clipboard.writeText("https://www.hourandcode.com/invite/12345")
                  .then(() => alert("Invite link copied to clipboard!"))
                  .catch(err => console.error('Failed to copy: ', err));
              }}
            >
              COPY
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Account;
