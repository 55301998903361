// instructionContent.js
import React, { useState, useEffect } from 'react';
import { Form, ListGroup } from 'react-bootstrap';
import { supabase } from '../../../utils/supabase'; // Import Supabase client
import './instructionContentStyles.css';

const InstructionContent = ({ step, session, courseId, lessonId, stepIndex, onComplete }) => {
  const [progress, setProgress] = useState({});
  const [fetchedProgress, setFetchedProgress] = useState(false);

  useEffect(() => {
    const fetchProgress = async () => {
      if (!session || !session.user || fetchedProgress) return;

      // Fetch UserCourse based on course_id
      const { data: userCourseData, error: userCourseError } = await supabase
        .from('UserCourses')
        .select('*')
        .eq('user_id', session.user.id)
        .eq('course_id', courseId)
        .single();

      if (userCourseError || !userCourseData) return;

      // Fetch saved progress from UserCourseLessons
      const { data: lessonData } = await supabase
        .from('UserCourseLessons')
        .select('*')
        .eq('user_course_id', userCourseData.id)
        .eq('lesson_id', lessonId)
        .eq('user_id', session.user.id)
        .single();

      if (lessonData && lessonData.progress && lessonData.progress[stepIndex]) {
        setProgress(lessonData.progress[stepIndex]);
      }

      setFetchedProgress(true);
    };

    fetchProgress();
  }, [session, courseId, lessonId, stepIndex, fetchedProgress]);

  const handleCheckboxChange = async (instructionId) => {
    const newProgress = {
      ...progress,
      [instructionId]: !progress[instructionId],
    };
    setProgress(newProgress);

    // Save progress to Supabase
    if (session && session.user) {
      // Fetch or create UserCourse
      let { data: userCourseData } = await supabase
        .from('UserCourses')
        .select('*')
        .eq('user_id', session.user.id)
        .eq('course_id', courseId)
        .single();

      if (!userCourseData) {
        // Create UserCourse
        const { data: newUserCourse } = await supabase
          .from('UserCourses')
          .insert({ user_id: session.user.id, course_id: courseId })
          .single();
        userCourseData = newUserCourse;
      }

      // Fetch or create UserCourseLesson
      let { data: lessonData } = await supabase
        .from('UserCourseLessons')
        .select('*')
        .eq('user_course_id', userCourseData.id)
        .eq('lesson_id', lessonId)
        .eq('user_id', session.user.id)
        .single();

      if (!lessonData) {
        // Create UserCourseLesson
        await supabase.from('UserCourseLessons').insert({
          user_course_id: userCourseData.id,
          lesson_id: lessonId,
          user_id: session.user.id,
          progress: { [stepIndex]: newProgress },
        });
      } else {
        // Update progress
        const updatedProgress = {
          ...lessonData.progress,
          [stepIndex]: newProgress,
        };
        await supabase
          .from('UserCourseLessons')
          .update({ progress: updatedProgress })
          .eq('id', lessonData.id);
      }
    }

    // Optionally, call onComplete if all instructions are completed
    const allCompleted = step.instructions.every((instr) => newProgress[instr.id]);
    if (allCompleted) {
      onComplete({ result: true });
    }
  };

  return (
    <div className="instruction-content mb-3 p-3 shadow-sm rounded">
      <h3>{step.title}</h3>
      <ListGroup variant="flush">
        {step.instructions.map((instruction) => (
          <ListGroup.Item key={instruction.id}>
            <Form.Check
              type="checkbox"
              label={instruction.text}
              checked={progress[instruction.id] || false}
              onChange={() => handleCheckboxChange(instruction.id)}
            />
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default InstructionContent;
