// QuizContent.js
import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import './quizContentStyles.css';

const QuizContent = ({ step, onComplete, stepIndex, reset }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [hasAnswered, setHasAnswered] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);

  useEffect(() => {
    if (reset) {
      setSelectedOption(null);
      setHasAnswered(false);
      setIsCorrect(false);
      setShowCorrectAnswer(false);
    }
  }, [reset]);

  const handleOptionChange = (event) => {
    setSelectedOption(parseInt(event.target.value));
  };

  const handleSubmit = () => {
    if (selectedOption !== null) {
      const correct = step.options[selectedOption].isCorrect;
      setIsCorrect(correct);
      setHasAnswered(true);

      if (!correct) {
        setShowCorrectAnswer(true);
      }

      onComplete({ result: correct ? 1 : 0 }); // Use 1 for correct, 0 for incorrect
    }
  };

  return (
    <div className="quiz-content mb-3 p-3 shadow-sm rounded">
      <h5>{step.question}</h5>
      <Form>
        {step.options.map((option, index) => (
          <Form.Check
            key={index}
            type="radio"
            name={`quiz-${stepIndex}`}
            id={`option-${stepIndex}-${index}`}
            value={index}
            onChange={handleOptionChange}
            disabled={hasAnswered}
            checked={selectedOption === index}
            label={
              <label htmlFor={`option-${stepIndex}-${index}`} className="option-label">
                {option.text}
              </label>
            }
          />
        ))}
      </Form>
      <div className="d-flex justify-content-end mt-2">
        {!hasAnswered && (
          <Button variant="warning" onClick={handleSubmit} disabled={selectedOption === null}>
            Submit
          </Button>
        )}
      </div>
      {hasAnswered && (
        <div className={`mt-3 ${isCorrect ? 'text-success' : 'text-danger'}`}>
          {isCorrect ? 'Correct! Well done.' : 'Incorrect. Please review the material and try again.'}
        </div>
      )}
      {showCorrectAnswer && (
        <div className="mt-2">
          <p>The correct answer is:</p>
          <ul>
            {step.options.map((option, index) =>
              option.isCorrect ? (
                <li key={index}>
                  <strong>{option.text}</strong>
                </li>
              ) : null
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default QuizContent;
