// InputContent.js
import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import './inputContentStyles.css';
import Prism from 'prismjs';
import { supabase } from '../../../utils/supabase';
import 'prismjs/themes/prism.css'; // Ensure Prism CSS is imported
import 'prismjs/components/prism-ruby';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-go';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-elixir';
import '../../../styles/prism-monokai.css'; // Import the local Monokai theme CSS
import ReactMarkdown from 'react-markdown';
import { diffLines } from 'diff'; // Import diffLines from 'diff' library

const InputContent = ({ onComplete, courseId, stepIndex, step, isCorrect, session, lessonId, levelId, reset }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [hasPassed, setHasPassed] = useState(false);
  const [inputValue, setInputValue] = useState(step.initialValue || '');
  const [userCourse, setUserCourse] = useState(null);
  const [fetchedContent, setFetchedContent] = useState(false);
  const [placeholder, setPlaceholder] = useState('Enter your text here');

  // Hint feature state variables
  const [hintCount, setHintCount] = useState(0);
  const [isHintActive, setIsHintActive] = useState(false);
  const [diffs, setDiffs] = useState([]); // To store diff objects
  const [revealedDiffsCount, setRevealedDiffsCount] = useState(0);

  useEffect(() => {
    // console.log('InputContent reset triggered');

    handleReset();
  }, [reset]);


  useEffect(() => {
    const fetchSavedProgress = async () => {
      if (fetchedContent === true) {
        return;
      }

      const { data: sessionData } = await supabase.auth.getSession();
      if (sessionData) {
        if (!session || !session.user) {
          return; // Exit if session is not available
        }

        // Fetch UserCourse based on course_id
        const { data: userCourseData, error: userCourseError } = await supabase
          .from('UserCourses')
          .select('*')
          .eq('user_id', session.user.id)
          .eq('course_id', courseId)
          .single();

        if (userCourseError) {
          console.error('Error fetching UserCourse:', userCourseError);
          return; // Exit if there's an error
        }

        if (userCourseData) {
          setUserCourse(userCourseData);
          // Fetch saved progress from UserCourseLessons
          const { data: lessonData, error: lessonError } = await supabase
            .from('UserCourseLessons')
            .select('*')
            .eq('user_course_id', userCourseData.id)
            .eq('lesson_id', lessonId)
            .single();

          if (lessonError && lessonError.code !== 'PGRST116') {
            console.error('Error fetching lesson progress:', lessonError);
            return; // Exit if there's an error
          }

          if (lessonData) {
            const stepProgress = lessonData.progress[stepIndex];
            if (stepProgress) {
              setInputValue(stepProgress.input || ''); // Load saved input if available
              setHasPassed(stepProgress.result === true); // Check if result is true
            }
          }

          setFetchedContent(true);
        }
      }
    };

    fetchSavedProgress();
    Prism.highlightAll(); // Ensure Prism highlights the code after rendering
  }, [step, courseId, stepIndex, session, lessonId, fetchedContent]);

  useEffect(() => {
    Prism.highlightAll(); // Highlight code blocks after component mounts and updates
  }, [step.content]);

  // Compute diffs between initialValue and expectedResult
  useEffect(() => {
    const computeDiffs = () => {
      const initialValue = step.initialValue || '';
      const expectedResult = Array.isArray(step.expectedResult)
        ? step.expectedResult[0]
        : step.expectedResult;

      const diffsResult = diffLines(initialValue, expectedResult);
      setDiffs(diffsResult);
      setRevealedDiffsCount(0);
      setHintCount(0);
    };

    computeDiffs();
  }, [step.initialValue, step.expectedResult]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setErrorMessage('');
    setIsHintActive(false);
    checkInputMatch(event.target.value);
  };

  const handleSubmit = async () => {
    setErrorMessage('');

    const isMatch = checkInputMatch(); // Check if the input matches the expected result
    const error = isMatch ? '' : getErrorMessage(inputValue); // Get error message if input is incorrect

    // Update local state
    setErrorMessage(error);

    if (error === '') {
      const progressUpdate = {
        input: inputValue,
        result: isMatch,
      };
      if (session || session?.user) {
        // Store progress in Supabase
        const { error: updateError } = await supabase.from('UserCourseLessons').upsert({
          user_course_id: userCourse.id,
          lesson_id: lessonId,
          progress: { [stepIndex]: progressUpdate },
        }, { onConflict: ['user_course_id', 'lesson_id'] });

        if (updateError) {
          console.error('Error updating progress:', updateError);
          return; // Exit if there's an error
        }
      }
      // Call onComplete with the progress update
      setHasPassed(true);
      onComplete(progressUpdate);
    }

    Prism.highlightAll();
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 500);
  };

  const checkInputMatch = (value = inputValue) => {
    const normalizeCode = (code) => {
      return code
        .trim()
        .replace(/\s+/g, ' ') // Replace multiple whitespace with single space
        .replace(/\n/g, '') // Remove newlines
        .replace(/\r/g, ''); // Remove carriage returns
    };

    const normalizedInput = normalizeCode(value);
    const regex = step.expectedRegex ? new RegExp(step.expectedRegex) : null;
    const expectedResults = Array.isArray(step.expectedResult) ? step.expectedResult : [step.expectedResult];

    const isMatch =
      expectedResults.some((expected) => {
        const normalizedExpected = normalizeCode(expected);
        return normalizedInput === normalizedExpected;
      }) || (regex && regex.test(normalizedInput));

    return isMatch;
  };

  const getErrorMessage = (userInput) => {
    const errorMessages = step.errorMessages || {};
    return errorMessages[userInput] || errorMessages['default'] || 'Incorrect. Try again.';
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && step.multiline !== 'true') {
      event.preventDefault();
      handleSubmit();
    } else if (event.key === 'Tab') {
      console.log('pressed tab')
      event.preventDefault();
      const indent = step.language === 'python' ? '    ' : '\t'; // 4 spaces for Python, tab for others
      const start = event.target.selectionStart;
      const end = event.target.selectionEnd;
      setInputValue(
        inputValue.substring(0, start) + indent + inputValue.substring(end)
      );
      setTimeout(() => {
        event.target.selectionStart = event.target.selectionEnd = start + indent.length;
      }, 0);
    } else {
      console.log('key pressed: ' +  event.key)
    }
  };

  // Function to parse content and extract code blocks
  const parseContent = (text) => {
    const parts = text.split(/(```[\s\S]*?```)/g);
    return parts.map((part, index) => {
      if (part.startsWith('```')) {
        // Code block
        const match = part.match(/```(.*?)\n([\s\S]*?)```/);
        if (match) {
          const language = match[1].trim();
          const code = match[2].trim();
          return (
            <pre key={index} className={`language-${language}`}>
              <code className={`language-${language}`}>{code}</code>
            </pre>
          );
        }
      } else {
        // Regular text
        return (
          <span key={index}>
            {part.split('\n').map((line, idx) => (
              <React.Fragment key={index + '-' + idx}>
                {line.split('`').map((segment, subIdx) =>
                  subIdx % 2 === 1 ? (
                    <code key={index + '-' + idx + '-' + subIdx}>{segment}</code>
                  ) : (
                    segment
                  )
                )}
                <br />
              </React.Fragment>
            ))}
          </span>
        );
      }
      return null;
    });
  };

  const PassIcon = () => (
    <svg
      aria-label="Pass Icon"
      aria-hidden="false"
      className="ml-2"
      height="24"
      role="img"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Pass Icon</title>
      <circle cx="12" cy="12" r="12" fill="#4CAF50" />
      <path
        d="M8.75 17.882l-4.359-4.359a.75.75 0 0 1 1.06-1.06l4.006 4.005 9.005-9.006a.75.75 0 0 1 1.061 1.061l-9.359 9.359a1 1 0 0 1-1.414 0z"
        fill="#FFF"
      />
    </svg>
  );

  const RefreshIcon = () => (
    <svg
      aria-label="Refresh Icon"
      aria-hidden="false"
      height="24"
      role="img"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Refresh Icon</title>
      <path d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z" fill="#9aa0a6" />
    </svg>
  );

  const handleFocus = () => {
    setPlaceholder(step.placeholder || 'Type your code...');
  };

  const handleBlur = () => {
    setPlaceholder('> Write your code here');
  };

  const handleReset = () => {
    setInputValue(step.initialValue || '');
    setErrorMessage('');
    setHasPassed(false);
    setHintCount(0); // Reset hint count on reset
    setIsHintActive(false);
    setRevealedDiffsCount(0);
    Prism.highlightAll();
    checkInputMatch(step.initialValue || '');
  };

  const handleHint = () => {
    const initialValue = step.initialValue || '';
    const expectedResult = Array.isArray(step.expectedResult)
      ? step.expectedResult[0]
      : step.expectedResult;

    const totalDiffs = diffs.filter((part) => part.added).length;

    if (revealedDiffsCount < totalDiffs) {
      // Reveal one more diff with each hint
      const newRevealedDiffsCount = revealedDiffsCount + 1;
      setRevealedDiffsCount(newRevealedDiffsCount);

      // Build the new inputValue with revealed diffs
      let revealedDiffs = 0;
      let newValue = '';
      diffs.forEach((part) => {
        if (part.added) {
          if (revealedDiffs < newRevealedDiffsCount) {
            // Reveal this added part
            newValue += part.value;
            revealedDiffs++;
          } else {
            // Skip this part (not yet revealed)
          }
        } else if (part.removed) {
          // Skip removed parts
        } else {
          // Unchanged part
          newValue += part.value;
        }
      });

      setInputValue(newValue);
      // Call checkInputMatch with the updated inputValue
      checkInputMatch(newValue);
      // Clear any existing error messages
      setErrorMessage('');
    } else {
      // All diffs have been revealed; show the full expected result
      setInputValue(expectedResult);
      checkInputMatch(expectedResult);
      setErrorMessage('');
    }

    setIsHintActive(true);
    setTimeout(() => {
      setIsHintActive(false);
    }, 500); // Pulsing effect lasts for 0.5 seconds

    setHintCount(hintCount + 1); // Increment hint count
  };

  return (
    <div className="input-content mb-3 p-3">
      <div>{parseContent(step.content)}</div>
      <br />
      <p>{hasPassed && <>Your Submission:</>}</p>
      <div className="d-flex flex-column">
        {!hasPassed && (
          <>
            <div className="col-12 mb-2">
              {step.multiline !== 'true' && (
                <div className="code-input-wrapper">
                  <Form.Control
                    type="text"
                    className={`${
                      isHintActive ? 'pulse-animation' : ''
                    } language-${step.language || 'plaintext'} codeblock`}
                    placeholder={placeholder}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    isInvalid={errorMessage !== ''}
                    data-record="true"
                  />
                </div>
              )}
              {step.multiline === 'true' && (
                <div className="code-input-wrapper">
                  <Form.Control
                    as="textarea"
                    rows={8}
                    className={`${
                      isHintActive ? 'pulse-animation' : ''
                    } language-${step.language || 'plaintext'} codeblock`}
                    placeholder={placeholder}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    value={inputValue}
                    onKeyDown={handleKeyDown}
                    onChange={handleInputChange}
                    isInvalid={errorMessage !== ''}
                    data-record="true"
                  />
                </div>
              )}
            </div>
            <div className="col-12 d-flex justify-content-end">
              {!hasPassed && (
                <>
                  <span className="me-2">
                    <Button
                      className="ml-2 btn-light border"
                      onClick={() => {
                        handleReset();
                        posthog.capture('Clicked Reset Challenge', {
                          contentType: 'inputContent',
                          stepIndex: stepIndex,
                          lessonId: lessonId,
                          levelId: levelId, // Assuming levelId is part of the step object
                          courseId: courseId,
                        });
                      }}
                    >
                      Reset challenge
                      <RefreshIcon />
                    </Button>
                  </span>
                  <span className="me-2">
                    <Button
                      className="ml-2 btn-light border"
                      onClick={() => {
                        handleHint();
                        posthog.capture('Clicked Hint', {
                          contentType: 'inputContent',
                          stepIndex: stepIndex,
                          lessonId: lessonId,
                          levelId: levelId, // Assuming levelId is part of the step object
                          courseId: courseId,
                        });
                      }}
                      disabled={revealedDiffsCount >= diffs.filter((part) => part.added).length}
                    >
                      {revealedDiffsCount < diffs.filter((part) => part.added).length ? 'Hint' : 'No more hints'}
                    </Button>
                  </span>
                  <Button variant="warning" className="d-flex run-button" onClick={handleSubmit}>
                    Run
                    <svg
                      aria-label="Return Icon"
                      aria-hidden="false"
                      className="ml-1"
                      height="24"
                      role="img"
                      viewBox="0 0 24 24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>Return Icon</title>
                      <g aria-hidden="true">
                        <path
                          d="M16.25 5a.75.75 0 0 1 .75.75v9.5a.75.75 0 0 1-.648.743L16.25 16H7.637l1.068 1.069a.75.75 0 0 1-1.06 1.06L5.22 15.706a.75.75 0 0 1 0-1.06l2.424-2.425a.75.75 0 0 1 1.061 1.06L7.483 14.5H15.5V5.75a.75.75 0 0 1 .75-.75z"
                          fill="#000000"
                        ></path>
                      </g>
                    </svg>
                  </Button>
                </>
              )}
            </div>
          </>
        )}
        {hasPassed && (
          <>
            <div className={`language-${step.language || 'plaintext'} align-items-center codeblock w-100`}>
              {step.multiline === 'true' && (
                <pre className="custom-pre">
                  {inputValue.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      <code key={index}>{line}</code>
                      <br />
                    </React.Fragment>
                  ))}
                </pre>
              )}
              {step.multiline !== 'true' && (
                <div className="code-input-wrapper">
                  <span className={`language-${step.language || 'plaintext'} codeblock 100w`}>
                    {inputValue}
                    <PassIcon className="justify-content-end" />
                  </span>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {hasPassed && step.returnedValue !== undefined && (
        <div className="">
          <p className="code-output">code output</p>
          <hr />
          <span className="submitted-state text-content">
            <ReactMarkdown>{step.returnedValue}</ReactMarkdown>
          </span>
          <div className="d-flex justify-content-end mt-2">
            <Button className="ml-2 btn-light border" onClick={handleReset}>
              Reset challenge
              <RefreshIcon />
            </Button>
          </div>
        </div>
      )}
      {!hasPassed && errorMessage && (
        <div className="mt-2">
          <Form.Control.Feedback type="invalid" className="d-block">
            {errorMessage}
          </Form.Control.Feedback>
        </div>
      )}
    </div>
  );
};

export default InputContent;
