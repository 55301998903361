export const courses = [
  {
    id: "introductionCourse",
    title: "Hourandcode Introduction – Start Here",
    description: "Start Here and learn the basics of taking interactive courses on Hourandcode.",
    thumbnail: '/images/courseStart.png',
  },
  // {
  //   id: "pythonIntroCourse",
  //   title: "Python Foundations: Rapid Mastery for Beginners",
  //   description: "Get a strong foundation in Python programming in less than 48 hours.",
  //   thumbnail: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxe6IR3EKgALq0lEUvpW3GmPH8rpAv1cK0_w&s',
  // },
  {
    id: "sqlPart1",
    title: "SQL Foundations: Getting Started with Databases",
    description: "Learn the basics of SQL and relational databases, including how to create, manage, and manipulate data.",
    thumbnail: "/images/courseSQL.png"
  },
  {
    id: "rubyBeginnerCourse",
    title: "Ruby Foundations: Rapid Mastery for Beginners",
    description: "Master the essentials of Ruby programming in just 2.5 weeks with our comprehensive, all-in-one course",
    thumbnail: '/images/courseRuby.png',
  },
  {
    id: "elixirFundamentals",
    title: "Elixir Fundamentals for Ruby Developers",
    description: "This course provides an introduction to Elixir, focusing on its syntax, functional nature, and how it contrasts with Ruby.",
    thumbnail: "/images/courseElixir.png",
    live: "false"
  },
  {
    id: "pythonPart1",
    title: "Mastering Python Series: Part 1 - Introduction to Python",
    description: "Learn the basics of Python and write your first Python program quickly!",
    thumbnail: "/images/coursePython.png",
    live: "false"
  },
  {
    id: "javascriptPart1",
    title: "JavaScript Mastery Series: Part 1 - Fundamentals",
    description: "Get started with JavaScript from scratch and build a solid foundation for using it in web development.",
    thumbnail: "/images/CourseJavascript.png",
    live: "true"
  },
  {
    id: "masteringGoLang1",
    title: "Mastering Golang Series: Part 1 - Introduction to Go",
    description: "Learn the basics of Golang and write your first Go program in no time!",
    thumbnail: '/images/courseGolang.png',
    live: "true"
  },
  {
    id: "bashFundamentals",
    title: "Bash Fundamentals for Everyday Development (Mac Terminal)",
    description: "Master the fundamentals of Bash in macOS terminal and boost your productivity by learning how to interact with the command-line efficiently.",
    thumbnail: "/images/courseBash.png",
    live: "true"
  },
  {
    id: "htmlFundamentals",
    title: "Mastering HTML Series: Part 1 - Introduction to HTML",
    description: "Learn the basics of HTML and start building your own web pages from scratch!",
    thumbnail: "/images/courseHTML5.png",
    live: "true"
  },
  {
    id: "cssFundamentals",
    title: "Mastering CSS Series: Part 1 - Introduction to CSS",
    description: "Learn the fundamentals of CSS and start styling your web pages with confidence!",
    thumbnail: "/images/courseCSS3.png",
    live: "true"
  },
  {
    id: "gitFundamentals",
    title: "Git Fundamentals for Everyday Development",
    description: "Master the fundamentals of Git and version control to track, manage, and collaborate on development projects efficiently.",
    thumbnail: "/images/courseGit.png",
    live: "true"
  },
  // {
  //   id: "phpCourse",
  //   title: "Mastering PHP: From Beginner to Advanced",
  //   description: "Learn PHP from scratch and build dynamic, server-side web applications.",
  //   thumbnail: "https://static-00.iconduck.com/assets.00/php-icon-256x256-oq5bc0bt.png",
  // },
  // {
  //   id: "sqlFundamental",
  //   title: "SQL fundamentals: from zero to data hero",
  //   description: "Learn the basics of and fundamental of SQL",
  //   thumbnail: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwuqWn7rCVhqZ_pSlxwVUzlZtFWaOMdbm28A&s',
  // },
  // {
  //   id: "graphqlFundamentals",
  //   title: "GraphQL Fundamentals: From Basics to Advanced Techniques",
  //   description: "Learn to build and optimize powerful GraphQL APIs through hands-on, real-world applications.",
  //   thumbnail: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/17/GraphQL_Logo.svg/2048px-GraphQL_Logo.svg.png',
  // },
  // {
  //   id: "graphqlFrontend",
  //   title: "GraphQL Front-End Mastery: Integrating with Apollo Client",
  //   description: "Master the art of integrating GraphQL with your front-end applications using Apollo Client.",
  //   thumbnail: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/17/GraphQL_Logo.svg/2048px-GraphQL_Logo.svg.png',
  // },
  // {
  //   id: "graphqlBackend",
  //   title: "GraphQL Back-End Mastery: Building and Optimizing Your API",
  //   description: "Learn to build and optimize powerful GraphQL APIs through hands-on, real-world applications. ",
  //   thumbnail: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/17/GraphQL_Logo.svg/2048px-GraphQL_Logo.svg.png',
  // }
];

