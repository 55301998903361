import React from 'react';
import './coverImageStyles.css';

const CoverImage = ({ thumbnail, title, subtitle }) => {
  return (
    <div className="cover-image" style={{ backgroundColor: 'black', top: '0px'}}>
      <div className="cover-content">
        <div className="cover-text">
          <h1>{title}</h1>
          <p className="subtitle">{subtitle}</p>
        </div>
        <div className="cover-thumbnail">
          <img src={thumbnail} alt={`${title} Thumbnail`} />
        </div>
      </div>
    </div>
  );
};

export default CoverImage;