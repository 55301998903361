// HTMLContent.js
import React, { useState, useEffect } from 'react';
import Prism from 'prismjs';
import DOMPurify from 'dompurify';
import './htmlContentStyles.css';
import 'prismjs/components/prism-markup'; // For HTML
import 'prismjs/components/prism-css';
import '../../../styles/prism-monokai.css';
import ReactMarkdown from 'react-markdown';
import { Button } from 'react-bootstrap';

const HTMLContent = ({ step, onComplete }) => {
  const [showOutput, setShowOutput] = useState(false);

  useEffect(() => {
    Prism.highlightAll();
  }, [step.content]);

  const handleRunCode = () => {
    setShowOutput(true);

    // Call onComplete to inform the parent that the step is completed
    if (onComplete) {
      onComplete({ result: true }); // Signal successful completion
    }
  };

  return (
    <div className="html-content mb-3 p-3 shadow-sm rounded">
      <div className="text-content">
        <ReactMarkdown>{step.intro}</ReactMarkdown>
      </div>
      <pre>
        <code className={`language-html`}>{step.content}</code>
      </pre>
      <div className="d-flex justify-content-end mt-2">
        {!showOutput && (
          <Button variant="warning" onClick={handleRunCode}>
            Run
          </Button>
        )}
      </div>
      {showOutput && (
        <div className="live-output mt-3">
          <p className="output-label">Output:</p>
          <div
            className="output-render"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(step.content) }}
          ></div>
        </div>
      )}
      {showOutput && step.description && (
        <div className="description mt-3">
          <ReactMarkdown>{step.description}</ReactMarkdown>
        </div>
      )}
    </div>
  );
};

export default HTMLContent;
