import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Prism from 'prismjs';
import ReactMarkdown from 'react-markdown';
import 'prismjs/themes/prism.css';
import 'prismjs/components/prism-ruby';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-go';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-elixir';
import '../../../styles/prism-laserwave.css';

const ClipboardContent = ({ step, onContinue, stepCompleted }) => {
  const [copied, setCopied] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isCompleted, setIsCompleted] = useState(stepCompleted);

  useEffect(() => {
    setIsCompleted(stepCompleted);
  }, [stepCompleted]);

  useEffect(() => {
    Prism.highlightAll();
  }, [step.content]);

  const fileIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-text me-2" viewBox="0 0 16 16">
      <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"/>
      <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
    </svg>
  );

  const handleCopy = () => {
    navigator.clipboard.writeText(step.content);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleContinue = () => {
    setIsCompleted(true);
    onContinue();
  };

  const copyIcon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" className="bi me-1" viewBox="0 0 16 16">
      <path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2z"/>
      <path d="M4 4h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2z"/>
    </svg>
  const checkIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="#000000"
      className="bi"
      viewBox="0 0 16 16"
    >
      <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
    </svg>
  );

  return (
    <>
      <div className="text-content"><ReactMarkdown>{step.intro}</ReactMarkdown></div>
      <div className="clipboard-content bg-light border rounded p-3 position-relative mb-3">
        <h6 className="mb-3 text-dark">{fileIcon}{step.path}</h6>
        <pre style={{backgroundColor: '#27212e'}} className={"p-2 rounded " + `language-${step.language}`}>
          <code style={{textShadow: 'none'}}>{step.content}</code>
        </pre>
        <Button
          variant="none"
          size="sm"
          className="position-absolute top-0 end-0 m-2"
          onClick={handleCopy}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {copied ? (
            <>
              Copied {checkIcon}
            </>
          ) : (
            <>
              Copy {copyIcon}
            </>
          )}
        </Button>
        {!isCompleted && (
          <Button
            variant="primary"
            className="mt-3"
            onClick={handleContinue}
          >
            Continue
          </Button>
        )}
      </div>
    </>
  );
};

export default ClipboardContent;
