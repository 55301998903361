import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import CustomNavbar from '../Navbar/CustomNavbar';
import CoverImage from './CoverImage';
import './courseStyles.css';
import { supabase } from '../../utils/supabase';
// import INSTRUCTOR_IMAGE from '../../images/instructor-rich.jpeg';

// Import FullCalendar and plugins
import FullCalendar from '@fullcalendar/react';  // Already imported
import dayGridPlugin from '@fullcalendar/daygrid';  // Already imported
import timeGridPlugin from '@fullcalendar/timegrid';  // New import
import listPlugin from '@fullcalendar/list';  // New import


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import posthog from 'posthog-js';

const Course = ({ session, account, isProject = false }) => {
  const navigate = useNavigate();
  const { id } = useParams(); // Use 'id' instead of 'courseId'
  const lockSVG = '/images/lock.svg';
  const [lessonCompleted, setLessonCompleted] = useState({}); // Initialize as an empty object
  const [currentUser, setCurrentUser] = useState(null);
  const [lessonsInProgress, setLessonsInProgress] = useState({});
  const [viewType, setViewType] = useState('hour'); // Default to 'hour' view
  const [subViewType, setSubViewType] = useState('list'); // 'list' or 'calendar'
  const [studySchedule, setStudySchedule] = useState([]);
  const [studyTimePerDayHours, setStudyTimePerDayHours] = useState(1);
  const [courseData, setCourseData] = useState(null); // State to store course data
  const [calendarEvents, setCalendarEvents] = useState([]);

  // Update fetchCourseData to handle both courses and projects
  const fetchCourseData = async () => {
    try {
      const response = await fetch(
        isProject
          ? `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/project-json-public/${id}/projectData.json`
          : `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/course-json-public/${id}/courseData.json`
      );
      // Debug
      // const response = await fetch('http://127.0.0.1:3007/json-data');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCourseData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Update useEffects to use 'id' instead of 'courseId'
  useEffect(() => {
    fetchCourseData();
  }, [id, isProject]);

  // Fetch study pace
  const fetchStudyPace = async () => {
    if (session && session?.user) {
      const { data, error } = await supabase
        .from('Accounts')
        .select('study_pace')
        .eq('user_id', session?.user.id)
        .single();

      if (error) {
        console.error('Error fetching study pace:', error.message);
      } else {
        const studyPace = data?.study_pace || 1; // Default to 1 if study_pace is not available
        setStudyTimePerDayHours(studyPace);
      }
    }
  };

  // Fetch course data and study pace
  useEffect(() => {
    const fetchData = async () => {
      await fetchCourseData();
      await fetchStudyPace();
    };
    fetchData();
  }, [id, session?.user?.id]);

  // Update study schedule when course data or study time per day changes
  useEffect(() => {
    if (courseData && studyTimePerDayHours) {
      const schedule = groupLessonsByStudyTime(courseData.levels, studyTimePerDayHours);
      setStudySchedule(schedule);
      const events = generateCalendarEvents(schedule);
      setCalendarEvents(events);
    }
  }, [courseData, studyTimePerDayHours]);

  useEffect(() => {
    if (session && session?.user && account?.stripe_subscription_status === 'active') {
      const fetchLessonsInProgress = async () => {
        const { data: userCourseData, error: userCourseError } = await supabase
          .from('UserCourses')
          .select('id') // Assuming 'id' is the user_course_id
          .eq('user_id', session.user.id)
          .eq('course_id', id)
          .single();

        if (userCourseError && userCourseError.code !== 'PGRST116') {
          console.error('Error fetching UserCourse:', userCourseError);
          return; // Exit if there's an error
        } else if (userCourseError && userCourseError.code === 'PGRST116') {
          // Create UserCourse if not found
          const { error: insertError } = await supabase.from('UserCourses').insert([
            {
              user_id: session.user.id,
              course_id: id,
              title: courseData.title,
              completed: false,
            },
          ]);

          if (insertError) {
            console.error('Error creating UserCourse:', insertError);
            return; // Exit if there's an error
          }
        }

        const { data, error } = await supabase
          .from('UserCourseLessons')
          .select('lesson_id, progress')
          .eq('user_id', session.user.id)
          .eq('user_course_id', userCourseData.id)
          .not('progress', 'is', null);

        if (error) {
          console.error('Error fetching lessons in progress:', error);
          return;
        }

        const inProgressLessons = data.reduce((acc, lesson) => {
          acc[lesson.lesson_id] = true;
          return acc;
        }, {});

        setLessonsInProgress(inProgressLessons);
      };

      fetchLessonsInProgress();
    }
  }, [session, id, account?.stripe_subscription_status]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (session && session?.user && account?.stripe_subscription_status === 'active') {
        const { email } = session.user;

        // Query the Accounts table for the authenticated user's email
        const { data, error } = await supabase
          .from('Accounts')
          .select('paid')
          .eq('email', email)
          .single(); // Get a single record

        if (error) {
          console.error('Error fetching user data:', error);
          setCurrentUser({ paid: false }); // Default to false if there's an error
        } else if (data) {
          setCurrentUser({ paid: data.paid }); // Set the paid status from the fetched data
        }
      } else {
        setCurrentUser({ paid: false }); // Default if session is not available
      }
    };

    fetchUserData();
  }, [session, account?.stripe_subscription_status]);

  useEffect(() => {
    if (
      courseData &&
      session &&
      session.user &&
      account?.stripe_subscription_status === 'active'
    ) {
      const fetchLessonCompletion = async () => {
        if (!session || !session.user) {
          return; // Exit if session is not available
        }

        const { data, error } = await supabase
          .from('UserCourses')
          .select('lessons_completed')
          .eq('user_id', session.user.id) // Get user ID from session
          .eq('course_id', id) // Add this line to filter by course_id
          .single();

        if (error || !data) {
          console.error('Error fetching lesson completion status:', error);
          return;
        }

        setLessonCompleted(data.lessons_completed || {});
      };

      fetchLessonCompletion();
    }
  }, [courseData, session, account?.stripe_subscription_status, id]);

  const handleLessonStart = (lesson, levelId) => {
    posthog.capture(isProject ? 'Project Lesson Start' : 'Course Lesson Start', {
      id: id,
      lessonId: lesson.id,
      lessonTitle: lesson.title,
      levelId: levelId,
      userId: session?.user?.id || 'anonymous'
    });
  };

  const handleLessonContinue = (lesson, levelId) => {
    posthog.capture(isProject ? 'Project Lesson Continue' : 'Course Lesson Continue', {
      id: id,
      lessonId: lesson.id,
      lessonTitle: lesson.title,
      levelId: levelId,
      userId: session?.user?.id || 'anonymous'
    });
  };

  const PassIcon = (
    <>
      &nbsp;
      <svg
        aria-label="Pass Icon"
        aria-hidden="false"
        className="ml-2"
        height="24"
        role="img"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Pass Icon</title>
        <circle cx="12" cy="12" r="12" fill="#4CAF50" />
        <path
          d="M8.75 17.882l-4.359-4.359a.75.75 0 0 1 1.06-1.06l4.006 4.005 9.005-9.006a.75.75 0 0 1 1.061 1.061l-9.359 9.359a1 1 0 0 1-1.414 0z"
          fill="#FFF"
        />
      </svg>
    </>
  );

  const isLessonCompleted = (lessonId) => {
    return !!lessonCompleted[lessonId];
  };

  const isLessonInProgress = (lessonId) => {
    return !!lessonsInProgress[lessonId];
  };

  // Function to count words
  const countWords = (text) => {
    return text ? text.split(/\s+/).filter((word) => word.length > 0).length : 0;
  };

  // Function to estimate time for a lesson
  const estimateTimeForLesson = (lesson) => {
    const averageWPM = 200; // Average reading speed in words per minute
    const codeStepTime = 60; // Time per code step in seconds
    const inputStepTime = 120; // Time per input step in seconds

    const totalWords = lesson.steps.reduce((total, step) => {
      if (step.type === 'text' || step.type === 'input') {
        return total + countWords(step.content);
      }
      return total;
    }, 0);

    const codeSteps = lesson.steps.filter((step) => step.type === 'code' || step.type === 'shell' || step.type === 'quizz' || step.type === 'instruction' || step.type === 'html' || step.type === 'clipboard' || step.type === 'codediff').length;
    const inputSteps = lesson.steps.filter((step) => step.type === 'input' || step.type === 'inputHtmL' || step.type === 'inputshell' || step.type === '').length;

    const readingTimeMinutes = totalWords / averageWPM;
    const codeStepsTimeMinutes = (codeSteps * codeStepTime) / 60;
    const inputStepsTimeMinutes = (inputSteps * inputStepTime) / 60;

    return readingTimeMinutes + codeStepsTimeMinutes + inputStepsTimeMinutes;
  };

  // Function to group lessons by study time per day (in hours) and include estimated time
  const groupLessonsByStudyTime = (levels, studyTimePerDayHours) => {
    const studyTimePerDayMinutes = studyTimePerDayHours * 60; // Convert hours to minutes
    const studySchedule = [];
    let currentDay = [];
    let currentDayTime = 0;

    Object.keys(levels).forEach((levelKey) => {
      const level = levels[levelKey];

      level.lessons.forEach((lesson) => {
        const estimatedTime = estimateTimeForLesson(lesson);
        const lessonWithTime = { ...lesson, estimatedTime, levelId: levelKey };

        if (currentDayTime + estimatedTime > studyTimePerDayMinutes) {
          studySchedule.push(currentDay);
          currentDay = [];
          currentDayTime = 0;
        }

        currentDay.push(lessonWithTime);
        currentDayTime += estimatedTime;
      });
    });

    // Add the last day if it has any lessons
    if (currentDay.length > 0) {
      studySchedule.push(currentDay);
    }

    return studySchedule;
  };

  // Helper function to get the time buffer duration in milliseconds
const getTimeBufferDuration = () => {
  const buffer = account?.time_buffer || '10min'; // Default buffer
  const bufferMap = {
    '5min': 5 * 60000,
    '10min': 10 * 60000,
    '25min': 25 * 60000,
    '30min': 30 * 60000,
    '1h': 60 * 60000,
  };
  return bufferMap[buffer] || 10 * 60000; // Default to 10 minutes
};

  // Helper function to get study window for a specific date
  const getStudyWindowForDate = (date) => {
    const preferredTime = account?.preferred_study_time || 'afternoon'; // Default to 'afternoon'
    const startTime = account?.start_time || '08:00';
    const endTime = account?.end_time || '22:00';

    let startHour, startMinute, endHour, endMinute;

    if (preferredTime === 'morning') {
      [startHour, startMinute] = startTime.split(':').map(Number);
      endHour = 12;
      endMinute = 0;
    } else if (preferredTime === 'afternoon') {
      startHour = 12;
      startMinute = 0;
      endHour = 18;
      endMinute = 0;
    } else if (preferredTime === 'evening') {
      startHour = 18;
      startMinute = 0;
      [endHour, endMinute] = endTime.split(':').map(Number);
    } else {
      // Default to full day
      startHour = 8;
      startMinute = 0;
      endHour = 22;
      endMinute = 0;
    }

    const studyWindowStart = new Date(date);
    studyWindowStart.setHours(startHour, startMinute, 0, 0);

    const studyWindowEnd = new Date(date);
    studyWindowEnd.setHours(endHour, endMinute, 0, 0);

    return {
      startHour,
      startMinute,
      endHour,
      endMinute,
      startTime: studyWindowStart,
      endTime: studyWindowEnd,
    };
  };

  // Generate calendar events for FullCalendar
  const generateCalendarEvents = (schedule) => {
    const events = [];
    let currentDate = new Date(); // Start from today

    if (!session || !session.user) {
      // For non-logged-in users, show a preview starting today
      currentDate = new Date();
    } else {
      // For logged-in users, start from their next available study time
      currentDate = getNextAvailableStudyTime();
    }

    schedule.forEach((dayLessons, index) => {
      // Calculate study window start and end times for the day
      let studyWindow = getStudyWindowForDate(currentDate);

      let lessonStartTime = new Date(studyWindow.startTime);

      dayLessons.forEach((lesson) => {
        const lessonDuration = lesson.estimatedTime * 60000; // Convert minutes to milliseconds
        const bufferDuration = getTimeBufferDuration(); // Get buffer duration in milliseconds

        const lessonEndTime = new Date(lessonStartTime.getTime() + lessonDuration);

        // If lesson end time exceeds study window end time, move to next day
        if (lessonEndTime > studyWindow.endTime) {
          // Move to the next day
          currentDate.setDate(currentDate.getDate() + 1);
          studyWindow = getStudyWindowForDate(currentDate);
          lessonStartTime = new Date(studyWindow.startTime);
          // Recalculate lesson end time
          lessonEndTime.setTime(lessonStartTime.getTime() + lessonDuration);
        }

        events.push({
          title: lesson.title,
          start: lessonStartTime.toISOString(),
          end: lessonEndTime.toISOString(),
          url: isProject ? `/projects/${id}/${lesson.levelId}/${lesson.id}` : `/courses/${id}/${lesson.levelId}/${lesson.id}`,
          extendedProps: {
            lessonId: lesson.id,
            levelId: lesson.levelId,
          },
        });

        // Update lessonStartTime for the next lesson (end time + buffer)
        lessonStartTime = new Date(lessonEndTime.getTime() + bufferDuration);
      });

      // Move to the next day for the next set of lessons
      currentDate.setDate(currentDate.getDate() + 1);
    });

    return events;
  };

  // Updated getNextAvailableStudyTime function
  const getNextAvailableStudyTime = () => {
    const today = new Date();
    const studyWindow = getStudyWindowForDate(today);

    // If current time is before the study window start, set to study window start
    if (today < studyWindow.startTime) {
      return studyWindow.startTime;
    }

    // If current time is within the study window, return current time
    if (today >= studyWindow.startTime && today <= studyWindow.endTime) {
      return today;
    }

    // If current time is after the study window, move to the next day
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    const nextStudyWindow = getStudyWindowForDate(nextDay);
    return nextStudyWindow.startTime;
  };

  return (
    <div>
      <CustomNavbar
        title="Hourandcode"
        session={session}
        showProgress={false}
        onBack={() => navigate(isProject ? `/projects` : `/courses`)}
      />
      {courseData && (
        <>
          <div className="d-block" style={{ paddingTop: '2rem' }}></div>
          <div className="container mt-4 col-12 pb-0">
            <CoverImage
              backgroundImage={courseData.coverImage.backgroundImage}
              thumbnail={courseData.coverImage.thumbnail}
              title={courseData.title}
              subtitle={courseData.coverImage.subtitle}
            />
          </div>
          <div className="container col-12">
            <div className="row">
              <div className="container mt-4 pt-1 col-md-3 col-12 order-last order-md-first">
                <h2 className="mb-4">About This Course</h2>
                <p>
                  <span className="count-about">
                    <b>{Object.keys(courseData.levels).length}</b> Modules
                  </span>{' '}
                  <span className="count-about">
                    <b>
                      {Object.values(courseData.levels).reduce(
                        (total, level) => total + level.lessons.length,
                        0
                      )}
                    </b>{' '}
                    Lessons
                  </span>
                </p>
                <p className="course-about">{courseData.about}</p>
                <h5>Prerequisites</h5>
                <p className="course-about">
                  {courseData.prerequisite &&
                    courseData.prerequisite.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        <span>{line}</span>
                        <br />
                      </React.Fragment>
                    ))}
                </p>
                {courseData.live == 'true' && (
                  <>
                    <h5>
                      Need help?
                      <span className="live-badge badge text-bg-dark">
                        {session && courseData.live === 'true' ? <span>🟢</span> : <span>🔴</span>} Office Hour
                      </span>
                    </h5>
                    <p className="course-office-hour course-about d-grid">
                      <p>
                        Need help with this lesson? Book a time to get personalized answers to your
                        questions.
                      </p>
                      {session ? (
                        <a href={courseData.officeHourUrl} className="btn btn-dark">
                          Schedule a Session
                        </a>
                      ) : (
                        <div className="text-center">
                          <a href="#" className="btn btn-dark disabled mb-2">
                            Schedule A Session
                          </a>
                          <br />
                          <span className="text-danger">require login</span>
                        </div>
                      )}
                    </p>
                  </>
                )}
              </div>
              <div className="container pt-1 mt-4 col-md-9 col-12">
                {/* Toggle Buttons */}
                <div className="text-center mt-3">
                  <div className="view-toggle">
                    <Button
                      onClick={() => setViewType('hour')}
                      className={viewType === 'hour' ? 'active btn-dark border' : 'btn-light border'}
                    >
                      Schedule View 🎉
                    </Button>
                    <Button
                      onClick={() => setViewType('module')}
                      className={
                        viewType === 'module' ? 'active btn-dark border' : 'btn-light border'
                      }
                    >
                      Module View 🗂
                    </Button>
                  </div>
                </div>
                <hr/>

                {/* Conditional Rendering based on viewType */}
                {viewType === 'hour' ? (
                  <div className="container pt-1 mt-3 col-md-12">
                    <p className="progress-insight">
                      You can complete this course in{' '}
                      <b className="text-dark">{studySchedule.length}</b> day
                      {studySchedule.length > 1 ? 's' : ''} with just{' '}
                      <b className="text-dark">{studyTimePerDayHours}</b> hour
                      {studyTimePerDayHours > 1 ? 's' : ''} a day.
                    </p>
                    {/* <hr /> */}
                    {session && session?.user && (
                      <>
                        {/* <h3>Customize Your Learning</h3> */}
                        <div className="col-12 d-flex justify-content-md-end">
                          <a
                            href="#"
                            onClick={() => navigate(`/account`)}
                            className="btn btn-success text-decoration-none w-100 w-lg-auto"
                          >
                            <b>Change Study Pace</b>
                          </a>
                        </div>
                      </>
                    )}
                    { !session && !session?.user && (
                      <p className="alert alert-warning" role="alert" style={{fontSize: '18px'}}><b>This is just a preview</b>; when you <a href="" onClick={() => navigate(`/authentication`)}>login</a>, we'll optimize everything around your exact preferences and availability.</p>
                    )
                    }
                    <hr />

                    {/* Sub-View Toggle */}
                    <ul className="nav nav-underline mb-3">
                      <li className="nav-item">
                        <a
                          className={`nav-link ${subViewType === 'list' ? 'active' : ''}`}
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setSubViewType('list');
                          }}
                        >
                          <FontAwesomeIcon icon={faList} /> List
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link ${subViewType === 'calendar' ? 'active' : ''}`}
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setSubViewType('calendar');
                          }}
                        >
                          <FontAwesomeIcon icon={faCalendarAlt} /> Calendar
                        </a>
                      </li>
                    </ul>
                    {/* Content based on subViewType */}
                    {subViewType === 'list' ? (
                      <>
                        {studySchedule.map((day, dayIndex) => (
                          <div key={dayIndex} className="day-schedule">
                            <h3>Day {dayIndex + 1}</h3>
                            <div className="lessons-list mt-2 mb-4">
                              {day.map((lesson, index) => (
                                <div key={index} className="lesson-item">
                                  <div className="lesson-info">
                                    <span className="lesson-title">
                                      {lesson.title}{' '}
                                      <span className="text-primary">
                                        ({Math.round(lesson.estimatedTime)} min)
                                      </span>
                                      {lesson.locked === 'true' && !currentUser?.paid ? (
                                        <span>
                                          <img src={lockSVG} alt="lock" className="lock" />
                                        </span>
                                      ) : (
                                        ''
                                      )}
                                      <span>
                                        {lessonCompleted &&
                                        Object.keys(lessonCompleted).length >= 0 ? (
                                          isLessonCompleted(lesson.id) ? (
                                            PassIcon
                                          ) : (
                                            ''
                                          )
                                        ) : (
                                          ' Loading...' // or any placeholder while loading
                                        )}
                                      </span>
                                    </span>
                                  </div>
                                  <Link to={isProject ? `/projects/${id}/${lesson.levelId}/${lesson.id}` : `/courses/${id}/${lesson.levelId}/${lesson.id}`}>
                                    {lessonCompleted &&
                                    Object.keys(lessonCompleted).length >= 0 ? (
                                      isLessonCompleted(lesson.id) ? (
                                        <Button variant="secondary">View</Button>
                                      ) : isLessonInProgress(lesson.id) ? (
                                        <Button
                                          variant="dark"
                                          onClick={() => handleLessonContinue(lesson, lesson.levelId)}
                                        >
                                          Continue
                                        </Button>
                                      ) : (
                                        <Button
                                          variant="primary"
                                          onClick={() => handleLessonStart(lesson, lesson.levelId)}
                                        >
                                          Start
                                        </Button>
                                      )
                                    ) : (
                                      <Button
                                        variant="primary"
                                        onClick={() => handleLessonStart(lesson, lesson.levelId)}
                                      >
                                        Start
                                      </Button>
                                    )}
                                  </Link>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </>
                    ) : subViewType === 'calendar' ? (
                      <div className="calendar-view mt-4">
                        <FullCalendar
                          plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
                          initialView="listWeek"
                          headerToolbar={{
                            left: 'prev,next,today',
                            center: '',
                            right: 'timeGridWeek,listWeek',
                          }}
                          views={{
                            listWeek: { buttonText: 'List' },
                            dayGridMonth: { buttonText: 'Month' },
                            timeGridWeek: { buttonText: 'Week' },
                            timeGridDay: { buttonText: 'Day' },
                          }}
                          events={calendarEvents}
                          eventClick={(info) => {
                            info.jsEvent.preventDefault(); // Prevent default behavior
                            if (info.event.url) {
                              navigate(info.event.url);
                            }
                          }}
                          height="auto"
                          initialDate={new Date()}
                        />

                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="container pt-1 mt-4 col-md-12 col-12">
                    {Object.entries(courseData.levels).map(([levelId, level]) => (
                      <div key={levelId} className="level-container mb-4">
                        <h2>{level.title}</h2>
                        <div className="lessons-list">
                          {level.lessons.map((lesson, index) => (
                            <div key={index} className="lesson-item">
                              <div className="lesson-info">
                                <span className="lesson-title">
                                  {lesson.title}
                                  {lesson.locked === 'true' && !currentUser?.paid ? (
                                    <span>
                                      <img src={lockSVG} alt="lock" className="lock" />
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                  <span>
                                    {lessonCompleted &&
                                    Object.keys(lessonCompleted).length >= 0 ? (
                                      isLessonCompleted(lesson.id) ? (
                                        PassIcon
                                      ) : (
                                        ''
                                      )
                                    ) : (
                                      ' Loading...' // or any placeholder while loading
                                    )}
                                  </span>
                                </span>
                              </div>
                              <Link to={isProject ? `/projects/${id}/${levelId}/${lesson.id}` : `/courses/${id}/${levelId}/${lesson.id}`}>
                                {lessonCompleted &&
                                Object.keys(lessonCompleted).length >= 0 ? (
                                  isLessonCompleted(lesson.id) ? (
                                    <Button variant="secondary">View</Button>
                                  ) : isLessonInProgress(lesson.id) ? (
                                    <Button
                                      variant="dark"
                                      onClick={() => handleLessonContinue(lesson,levelId)}
                                    >
                                      Continue
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="primary"
                                      onClick={() => handleLessonStart(lesson, levelId)}
                                    >
                                      Start
                                    </Button>
                                  )
                                ) : (
                                  <Button
                                    variant="primary"
                                    onClick={() => handleLessonStart(lesson, levelId)}
                                  >
                                    Start
                                  </Button>
                                )}
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}

                    {/* <a href="#" className="btn btn-light mt-4 text-primary border">
                      <img
                        src="https://static-00.iconduck.com/assets.00/google-icon-2048x2048-pks9lbdv.png"
                        alt="Google Logo"
                        style={{ width: '20px', marginRight: '8px' }}
                      />
                      Sync Google Calendar
                    </a> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Course;
