import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import ProgressBar from './ProgressBar';
import './navbarStyles.css';
import { supabase } from '../../utils/supabase';
import { FaBars } from 'react-icons/fa'; // Import the burger icon
import { useLocation } from 'react-router-dom';

const CustomNavbar = ({ progress, onBack, showProgress = true, title, session }) => {
  const logo = '/images/HourandcodeLOGO.png';
  const location = useLocation();

  const openCrispChat = () => {
    if (window.$crisp) {
      // Open the chat first
      window.$crisp.push(['do', 'chat:open']);

      // Set the pre-filled message after opening the chat
      window.$crisp.push(["set", "message:text", ["Hi! I’d love to share some feedback about my experience."]]);
    }
  };

  // Add this function to get the display name
  const getDisplayName = () => {
    if (session?.user?.user_metadata?.first_name) {
      return session.user.user_metadata.first_name;
    }
    return session?.user?.email || 'User';
  };

  return (
    <Navbar bg="light" expand="lg" fixed="top" className="shadow-sm">
      <Navbar.Brand href="#" onClick={onBack}>
        {/* {title ? title : '←'} */}
       { title ? <img src={logo} alt="lock" className="logo" /> : '←'}
      </Navbar.Brand>

      <Nav className="ml-auto d-none d-lg-flex">
          <Nav.Link href="/mycourses">My Courses</Nav.Link>
          <Nav.Link href="/courses">Catalog</Nav.Link>
          {/* <Nav.Link href="/projects">Projects</Nav.Link> */}
      </Nav>
      { location.pathname !== '/authentication' &&
        <Nav className="ml-auto d-none d-lg-flex">
            <Nav.Link onClick={openCrispChat}>Send Feedback</Nav.Link>
        </Nav>
      }
      {showProgress && (
        <div className="progress-container me-4 d-none d-md-block">
          <span>Progress:</span>
          <ProgressBar progress={progress} />
        </div>
      )}
      { !session && location.pathname !== '/authentication' &&  (
        <>
          <Nav className="ms-auto d-lg-flex me-2 d-none">
              <Nav.Link href="/authentication" className="border rounded">Log In</Nav.Link>
              <Nav.Link href="/authentication" className="bg-primary text-white ms-2 border rounded">Sign Up</Nav.Link>
          </Nav>
        </>

      )}

      { session && location.pathname !== '/authentication' &&  (
        <Nav className="ms-auto d-none d-lg-flex">
          <span
            className="justify-content-end custom-dropdown bg-light border rounded p-2"
            style={{backgroundColor: "#6c757d", color: '#6c757d'}}
          >
            {getDisplayName()}
          </span>
          <NavDropdown title={<FaBars />} id="basic-nav-dropdown" className="justify-content-end custom-dropdown">
            { session && (
              <>
                <NavDropdown.Item href="/account">Account</NavDropdown.Item>
                <NavDropdown.Item href="https://hourandcode.canny.io/feedback">Feature Request</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#" onClick={() => supabase.auth.signOut()}>Log Out</NavDropdown.Item>
              </>
            ) }
          </NavDropdown>
        </Nav>
      )}
      <Nav className="ml-auto d-block ">
        <NavDropdown title={<FaBars />} id="basic-nav-dropdown" className="ml-auto custom-dropdown d-lg-none">
          { session && (
            <>
              <NavDropdown.Header >
                <span
                  className="justify-content-end custom-dropdown bg-light border rounded p-2"
                >
                  {getDisplayName()}
                 </span>
              </NavDropdown.Header>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/mycourses">My courses</NavDropdown.Item>
              <NavDropdown.Item href="/courses">Catalog</NavDropdown.Item>
              {/* <NavDropdown.Item href="/projects">Projects</NavDropdown.Item> */}
              <NavDropdown.Item href="/account">Account</NavDropdown.Item>
              <NavDropdown.Item href="https://hourandcode.canny.io/feedback">Feature Request</NavDropdown.Item>
              <NavDropdown.Item onClick={openCrispChat}>Send Feedback</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#" onClick={() => supabase.auth.signOut()}>Log Out</NavDropdown.Item>
            </>
          ) }
            { !session &&
              <>
                <NavDropdown.Item href="/authentication"><span style={{display: 'inline-block'}} className="bg-primary w-100 text-white p-2 rounded">Sign Up</span></NavDropdown.Item>
                <NavDropdown.Item href="/authentication">Sign In</NavDropdown.Item>
                <NavDropdown.Item href="/courses">Courses</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={openCrispChat}>Send Feedback</NavDropdown.Item>
              </>
            }

        </NavDropdown>
      </Nav>

    </Navbar>
  );
};

export default CustomNavbar;
