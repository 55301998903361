import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Prism from 'prismjs';
import ReactMarkdown from 'react-markdown';
import { diffLines, formatLines } from 'unidiff';
import 'prismjs/themes/prism.css';
import 'prismjs/components/prism-diff';
import '../../../styles/prism-laserwave.css';
import './CodeDiffContent.css';

const CodeDiffContent = ({ step, onContinue, stepCompleted }) => {
  const [showDiff, setShowDiff] = useState(true);
  const [isCompleted, setIsCompleted] = useState(stepCompleted);
  const isGitHubStyle = step.gui === true || step.gui === "true";
  const hideToggle = step.hide_toggle === true || step.hide_toggle === "true";

  useEffect(() => {
    Prism.highlightAll();
  }, [step.before, step.after, showDiff, isGitHubStyle]);

  useEffect(() => {
    setIsCompleted(stepCompleted);
  }, [stepCompleted]);

  const fileIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-text me-2" viewBox="0 0 16 16">
      <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"/>
      <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
    </svg>
  );

  const handleContinue = () => {
    setIsCompleted(true);
    onContinue();
  };

  const generateDiff = () => {
    if (isGitHubStyle) {
      const diff = diffLines(step.before, step.after);
      return diff.map((part, index) => {
        const prefix = part.added ? '+' : part.removed ? '-' : ' ';
        const lines = part.value.split('\n').filter(line => line.length > 0);
        return lines.map((line, lineIndex) => (
          <div key={`${index}-${lineIndex}`} className={`diff-line ${part.added ? 'added' : part.removed ? 'removed' : ''}`}>
            <span className="line-number"></span>
            <span className="line-number"></span>
            <span className="line-content">{`${prefix}${line}`}</span>
          </div>
        ));
      });
    } else {
      const diff = diffLines(step.before, step.after);
      return formatLines(diff, { context: 3 });
    }
  };

  const contentToShow = showDiff ? generateDiff() : step.after;

  return (
    <>
      <div className="text-content"><ReactMarkdown>{step.intro}</ReactMarkdown></div>
      <div className={`code-diff-content bg-light border rounded p-3 position-relative mb-3 ${isGitHubStyle ? 'github-style' : ''}`}>
        <h6 className="mb-3 text-dark">{fileIcon}{step.path}</h6>
        {!hideToggle && (
          <div className="d-flex justify-content-end mb-2">
            <Button
              variant={showDiff ? "outline-primary" : "primary"}
              size="sm"
              className="me-2"
              onClick={() => setShowDiff(false)}
            >
              Final Version
            </Button>
            <Button
              variant={showDiff ? "primary" : "outline-primary"}
              size="sm"
              onClick={() => setShowDiff(true)}
            >
              Show Diff
            </Button>
          </div>
        )}
        <pre style={{backgroundColor: isGitHubStyle ? '#f6f8fa' : '#27212e'}} className={`p-2 rounded ${isGitHubStyle ? '' : `language-${showDiff ? 'diff' : step.language}`}`}>
          {isGitHubStyle ? (
            <code className="github-diff">{contentToShow}</code>
          ) : (
            <code style={{textShadow: 'none'}}>{contentToShow}</code>
          )}
        </pre>
        {!isCompleted && (
          <Button
            variant="primary"
            className="mt-3"
            onClick={handleContinue}
          >
            Continue
          </Button>
        )}
      </div>
    </>
  );
};

export default CodeDiffContent;
