import React from 'react';
import CourseList from '../CourseList/CourseList';

const Projects = ({ session }) => {
  return (
    <CourseList
      session={session}
      isProject={true}
      title="Projects"
      description="Build real-world applications with guided projects"
    />
  );
};

export default Projects;
