// InputShellContent.js
import React, { useState, useEffect } from 'react';
import './inputShellContentStyles.css';
import { Button } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';

const InputShellContent = ({ step, onComplete, reset }) => {
  const [commandHistory, setCommandHistory] = useState([]);
  const [currentCommand, setCurrentCommand] = useState('');
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [hasCompleted, setHasCompleted] = useState(false);

  useEffect(() => {
    setCommandHistory([]);
    setCurrentCommand('');
    setCurrentStepIndex(0);
    setHasCompleted(false);
    // Reset hint-related states if they exist
    // setHintCount(0);
    // setIsHintActive(false);
  }, [reset]);

  const handleCommandSubmit = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const trimmedCommand = currentCommand.trim();
      let output = '';
      let errorMessage = '';

      // Function to normalize commands by escaping quotes
      const normalizeCommand = (command) => {
        return command.replace(/"/g, '\\"');
      };

      const normalizedCommand = normalizeCommand(trimmedCommand);
      const requiredCommand = normalizeCommand(step.requiredCommands[currentStepIndex]);
      console.log("requiredCommand: " + requiredCommand )
      console.log("normalizedCommand: " + normalizedCommand)
      console.log("boolean:" + ( normalizedCommand === requiredCommand))
      console.log("command normalizedCommand:" + step.commands[normalizedCommand])
      console.log("command trimmedCommand:" + step.commands[trimmedCommand])
      // Check if the command is in the available commands
      if (step.commands[trimmedCommand] !== undefined) {
        // Check if the command is the next required command
        console.log("in heere")
        if (requiredCommand === normalizedCommand) {
          console.log('goooot here')
          output = step.commands[trimmedCommand];
          setCurrentStepIndex(currentStepIndex + 1);
          if (currentStepIndex + 1 === step.requiredCommands.length) {
            setHasCompleted(true);
            onComplete({ result: true });
            setTimeout(() => {
              window.scrollTo(0, document.body.scrollHeight);
            }, 500);
          }
        } else if (step.requiredCommands.includes(normalizedCommand)) {
          // Command is required but out of order
          errorMessage = step.errorMessages.sequence[normalizedCommand] || 'Please follow the correct sequence of commands.';
        } else {
          // Command is available but not required
          output = step.commands[trimmedCommand];
        }
      } else {
        // Command not found
        errorMessage = step.errorMessages.notFound.replace('{command}', normalizedCommand);
      }

      setCommandHistory([
        ...commandHistory,
        { command: currentCommand, output: output || errorMessage }
      ]);
      setCurrentCommand('');
    }
  };

  // Generate progress dots
  const renderProgressDots = () => {
    return (
      <div className="progress-dots">
        {step.requiredCommands.map((_, index) => (
          <span
            key={index}
            className="progress-dot"
            style={{ backgroundColor: index < currentStepIndex ? '#ffc008' : '#ffffff' }}
          ></span>
        ))}
      </div>
    );
  };

  const formatOutput = (output) => {
    // Replace \n with <br /> for new lines
    const formattedOutput = output.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
    return formattedOutput;
  };

  return (
    <>
      <p className="shell-text-content">
        <ReactMarkdown>
          {step.content}
        </ReactMarkdown>
      </p>
      <div className="shell-content mb-3 p-3 shadow-sm rounded">
        <div className="shell-output">
          {commandHistory.map((entry, index) => (
            <div key={index}>
              <span className="shell-prompt">{step.prompt ? step.prompt : 'user@host:~$ '}</span> {entry.command}
              {formatOutput(entry.output) && (
                <div className="shell-response">{formatOutput(entry.output)}</div>
              )}
            </div>
          ))}
          {hasCompleted && (
            <div className="shell-success">{step.successMessage}</div>
          )}
        </div>
        {!hasCompleted && (
          <div className="shell-input">
            <span className="shell-prompt">{step.prompt ? step.prompt : 'user@host:~$ '}</span>
            <input
              tabindex={0}
              type="text"
              value={currentCommand}
              onChange={(e) => setCurrentCommand(e.target.value)}
              onKeyDown={handleCommandSubmit}
              autoFocus
              data-record="true"
            />
          </div>
        )}
        <div className="shell-footer d-flex justify-content-between align-items-center mt-2">
          {renderProgressDots()}
          {hasCompleted && (
            <span>✅</span>
          )}
        </div>
      </div>
    </>
  );
};

export default InputShellContent;
