import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Prism from 'prismjs';
import { supabase } from '../../../utils/supabase';// Import Supabase client
import './codeContentStyles.css';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-ruby';
import 'prismjs/components/prism-go';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-elixir';
import '../../../styles/prism-monokai.css'; // Import the local Monokai theme CSS
import ReactMarkdown from 'react-markdown';

const CodeContent = ({ step, onComplete, courseId, stepIndex, reset, lessonId, session }) => {
  const [showResult, setShowResult] = useState(false);
  const [codeInputValue, setCodeInputValue] = useState('');
  const [fetchedContent, setFetchedContent] = useState(false);  // Ensure this is defined

  useEffect(() => {
    const fetchSavedProgress = async () => {
      if (fetchedContent === true){
        console.log('no codeContent fetch needed')
        return;
      }

      const { data: sessionData } = await supabase.auth.getSession();
      if (sessionData) {
        if (!session || !session.user) {
          return; // Exit if session is not available
        }

        // Fetch UserCourse based on course_id
        const { data: userCourseData, error: userCourseError } = await supabase
          .from('UserCourses')
          .select('*')
          .eq('user_id', session.user.id)
          .eq('course_id', courseId)
          .single();


        if (userCourseError) {
          console.error("Error fetching UserCourse:", userCourseError);
          return; // Exit if there's an error
        } else {
          // console.log('no error ~~~~~');
        }

        if (userCourseData) {
          // Fetch saved progress from UserCourseLessons
          const { data: lessonData, error: lessonError } = await supabase
            .from('UserCourseLessons')
            .select('*')
            .eq('user_course_id', userCourseData.id)
            .eq('user_id', session.user.id)
            .eq('lesson_id', lessonId)
            .single();

          if (lessonError && (lessonError.code !== 'PGRST116')) {
            console.error("CodeContent: Error fetching lesson progress:", lessonError);
            console.log("userCourse.id: " + userCourseData.id)
            console.log("lesson_id: " +  lessonId)
            return; // Exit if there's an error
          } else if  (lessonError && (lessonError.code === 'PGRST116')){
            console.log("CodeContent Error PGRST116")
          }

          if (lessonData) {
            // console.log('[ shoooooow results ]');
            // console.log('lessonData ' + JSON.stringify(lessonData.progress));
            const stepProgress = lessonData.progress[stepIndex];

            if (stepProgress && stepProgress.result) {
              setShowResult(stepProgress.result);
              setCodeInputValue(stepProgress.input || ''); // Load saved input if available
            } else {
              setShowResult(false);
            }
          } else {
            // console.log("nooo lessson DATA~~~")
          }
          setFetchedContent(true)
        } else {
          // console.log("noooo userCourseData~~~~")
        }
      }
    };

    fetchSavedProgress();


    Prism.highlightAll(); // Ensure Prism highlights the code after rendering
  }, [step.content, reset, courseId, lessonId, session, stepIndex, fetchedContent]);


  const handleRunCode = async() => {
    // console.log('Run Code clicked');
    setShowResult(true);

    // Prepare the progress update object
    const progressUpdate = {
      result: true, // Assuming the code runs successfully
      input: codeInputValue, // Ensure this variable is defined and holds the correct value
      error: '' // No error if the code runs successfully
    };

    onComplete(progressUpdate); // Ensure this is called with the correct object

    // Save progress to Supabase
    if (session || session?.user) {
      // Fetch UserCourse based on course_id
      const { data: userCourseData, error: userCourseError } = await supabase
      .from('UserCourses')
      .select('*')
      .eq('user_id', session.user.id)
      .eq('course_id', courseId)
      .single();

      if (userCourseError) {
        console.error("Error fetching UserCourse:", userCourseError);
        return; // Exit if there's an error
      }

      if (userCourseData) {
        // Update or create UserCourseLessons
        const { data: lessonData, error: lessonError } = await supabase
          .from('UserCourseLessons')
          .select('*')
          .eq('user_course_id', userCourseData.id)
          .eq('lesson_id', lessonId)
          .eq('user_id', session.user.id)
          .single();

        if (lessonError) {
          console.error("Code Content:Error fetching lesson progress:", lessonError);
          return; // Exit if there's an error
        }

        if (lessonData) {
          // Update existing lesson progress
          const { error: updateError } = await supabase
            .from('UserCourseLessons')
            .update({
              progress: { ...lessonData.progress, [stepIndex]: progressUpdate }
            })
            .eq('id', lessonData.id);

          if (updateError) {
            console.error("Error updating lesson progress:", updateError);
          }
        } else {
          // Create UserCourseLesson if not found
          const { error: insertError } = await supabase
            .from('UserCourseLessons')
            .insert([{
              user_course_id: userCourseData.id,
              lesson_id: lessonId,
              progress: { [stepIndex]: progressUpdate }
            }]);

          if (insertError) {
            console.error("Error creating UserCourseLesson:", insertError);
          }
        }
      }

    }
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 500);
  };

  const PassIcon = (
    <svg aria-label="Pass Icon" aria-hidden="false" className="ml-2" height="24" role="img" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <title>Pass Icon</title>
      <circle cx="12" cy="12" r="12" fill="#4CAF50" />
      <path d="M8.75 17.882l-4.359-4.359a.75.75 0 0 1 1.06-1.06l4.006 4.005 9.005-9.006a.75.75 0 0 1 1.061 1.061l-9.359 9.359a1 1 0 0 1-1.414 0z" fill="#FFF" />
    </svg>
  );

  const ReturnIcon = (
    <svg aria-label="Return Icon" aria-hidden="false" className="ml-1" height="24" role="img" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <title>Return Icon</title>
      <g aria-hidden="true">
        <path d="M16.25 5a.75.75 0 0 1 .75.75v9.5a.75.75 0 0 1-.648.743L16.25 16H7.637l1.068 1.069a.75.75 0 0 1-1.06 1.06L5.22 15.706a.75.75 0 0 1 0-1.06l2.424-2.425a.75.75 0 0 1 1.061 1.06L7.483 14.5H15.5V5.75a.75.75 0 0 1 .75-.75z" fill="#000000"></path>
      </g>
    </svg>
  );

  return (
    <>
      <div className="text-content"><ReactMarkdown>{step.intro}</ReactMarkdown></div>
      <div className="code-content mb-3 p-3 shadow-sm rounded">
        <pre>
          <code className={`language-${step.language || 'plaintext'}`}>
            {step.content}
          </code>
        </pre>
        <div className="result-section">
          <p className="code-output">{ step.expectedResult === '' ? "" : "code output"}</p>
          <hr/>
          {showResult ? (
            <div className="submitted-state  d-flex align-items-center">
             { step.expectedResult === '' ? (
              <span>{step.description}</span>
              ) : "" }
              <span dangerouslySetInnerHTML={{ __html: step.expectedResult.replace(/\n/g, '<br />') }} />
              <span>{PassIcon}</span>
            </div>
          ) : (
            <>
              <div className="pre-run-description">
                <ReactMarkdown>
                  {step.description}
                </ReactMarkdown>
              </div>
              <div className="d-flex justify-content-end">
                <Button variant="warning" className="ml-2 d-flex align-items-center" onClick={handleRunCode}>
                  <b>{ step.expectedResult === '' ? "Continue" : "Run Code" }</b>
                  {ReturnIcon}
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CodeContent;
