import Rollbar from 'rollbar';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react'; // Update this import
import React, {useState, useEffect} from  "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router-dom'; // Add this import
import CourseList from "./components/CourseList/CourseList";
import Course from "./components/Course/Course";
import Lesson from "./components/Lesson/Lesson";
import Account from "./components/Account/Account";
import Pricing from "./components/Pricing/Pricing";
import Checkout from "./components/Checkout/Checkout";
import Return from "./components/Checkout/Return";
import Authentication from "./components/Authentication/Authentication";
import UserCourseList from "./components/UserCourseList/UserCourseList";
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/prism-monokai.css';
import './styles.css';
import { supabase } from './utils/supabase';
import posthog from 'posthog-js'; // Add this import
import Projects from './components/Projects/Projects';
import Project from './components/Project/Project';
import { useRollbar } from '@rollbar/react';

const rollbar = new Rollbar({
  accessToken: process.env.REACT_APP_ROLLBAR_KEY,
  environment: process.env.REACT_APP_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true
      }
    }
  }
});

// Initialize PostHog
// if (process.env.REACT_APP_ENV === 'production') {
  posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, {
     api_host: 'https://app.posthog.com',
     session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true, // Highly recommended as a minimum!!
        // color: false,
        // date: false,
        // 'datetime-local': false,
        // email: false,
        // month: false,
        // number: false,
        // range: false,
        // search: false,
        // tel: false,
        // text: false,
        // time: false,
        // url: false,
        // week: false,
        // textarea: false,
        // select: false,
      }
     }
    });
  // posthog.capture('my event', { property: 'value' })
  // console.log('posthog is live')
// }


// function TestError() {
//   const a = null
//   return a.hello()
// }

// Function to load Google Analytics script
const loadGoogleAnalytics = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = 'https://www.googletagmanager.com/gtag/js?id=G-YD10X05EPZ';
    document.head.appendChild(script1);

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-YD10X05EPZ');
    `;
    document.head.appendChild(script2);
  } else {
    return
  }

};

const App = () => {
  const [session, setSession] = useState(null)
  const [account, setAccount] = useState(null); // Add state for account
  const [loading, setLoading] = useState(true); // Add loading state

  // rollbar.error('Test error from localhost');


  useEffect(() => {
    const fetchSessionAndAccount = async () => {
      setLoading(true);
      const { data: { session } } = await supabase.auth.getSession();
      setSession(session);


      if (session) {
        // Configure Rollbar with user information
        rollbar.configure({
          payload: {
            person: {
              id: session.user.id,
              email: session.user.email,
              username: session.user.email.split('@')[0] // Using email as username
            }
          }
        });

        const { data: accountData } = await supabase
          .from('Accounts')
          .select('*')
          .eq('email', session.user.email)
          .single();

        setAccount(accountData);
      }
      setLoading(false);
    };

    fetchSessionAndAccount();

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      // Fetch account again on auth state change
      if (session) {
        fetchSessionAndAccount();
      } else {
        setAccount(null);

        // Reset Rollbar configuration when user logs out
        rollbar.configure({
          payload: {
            person: null
          }
        });
      }
    })
  }, [rollbar])

  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "5e2e26e8-6d07-4e96-b6ca-7cd5667e84c2";
    (function() {
      const d = document;
      const s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }, []);

  useEffect(() => {
    if (session) {
      posthog.identify(session.user.id, {
        email: session.user.email,
      });
    }
  }, [session]);

  // console.log("account: " + JSON.stringify(account))
  // console.log("account?: " + (account && (account.stripe_subscription_status === 'active')))

  useEffect(() => {
    loadGoogleAnalytics();
  }, []);

  if (loading) {
    return <div>...</div>; // Render loading state
  }

  return (
    <RollbarProvider config={rollbar}>
      <ErrorBoundary>
        <Router>
          <div className="app-container">
            <Routes>
              <Route path="/" element={<CourseList session={session}/>} />
              <Route path="/authentication" element={session ? <Navigate to="/courses" /> : <Authentication session={session} />} />
              {/* <Route path="/mycourses" element={session ? (account && (account.stripe_subscription_status === 'active') ? <UserCourseList session={session} /> : <Navigate to="/checkout" />) : <UserCourseList />} /> */}
              <Route path="/mycourses" element={session ? <UserCourseList session={session} account={account} /> : <Navigate to="/courses" />} />
              <Route path="/account" element={session ? <Account session={session} /> : <Course session={session} account={account} />} />
              <Route path="/checkout" element={<Checkout session={session} />} />
              <Route path="/return" element={<Return session={session} />} />
              <Route path="/upgrade" element={<Pricing session={session} />} />
              {/* <Route path="/courses" element={session ? (account && (account.stripe_subscription_status === 'active') ? <CourseList session={session} /> : <Navigate to="/checkout" />) : <CourseList />} /> */}
              <Route path="/courses" element={<CourseList session={session} /> } />
              {/* <Route path="/courses/:courseId" element={session ? (account && account.paid ? <Course session={session} /> : <Navigate to="/checkout" />) : <Course />} /> */}
              <Route path="/courses/:id" element={<Course session={session} account={account} />} />
              {/* <Route path="/courses/:courseId/:levelId/:lessonId" element={session ? (account && (account.stripe_subscription_status === 'active') ? <Lesson session={session} /> : <Navigate to="/checkout" />) : <Lesson />}/> */}
              <Route path="/courses/:id/:levelId/:lessonId" element={<Lesson session={session} account={account} />} />
              <Route path="/projects" element={<Projects session={session} />} />
              <Route path="/projects/:id" element={<Course session={session} account={account} isProject={true} />} />
              <Route path="/projects/:id/:levelId/:lessonId" element={<Lesson session={session} account={account} isProject={true} />} />
            </Routes>
          </div>
        </Router>
      </ErrorBoundary>
    </RollbarProvider>
  );
};

export default App;
