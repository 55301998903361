import React, { useState } from 'react';


const Plan = ({}) => {
  const [isYearly, setIsYearly] = useState(false);

  const togglePricing = () => {
    setIsYearly(!isYearly);
  };

  const yearlyStripeLink =  process.env.REACT_APP_YEARLY_STRIPE_LINK // 'https://buy.stripe.com/test_bIY14Reaa31i8FOaEG'
  const monthlyStripeLink = process.env.REACT_APP_MONTHLY_STRIPE_LINK //'https://buy.stripe.com/test_5kA6pbaXY7hy1dm8ww'

  console.log('yearlyStripeLink:  ' + yearlyStripeLink);
  console.log('monthlyStripeLink: ' + monthlyStripeLink );
  return (
    <div className="container pt-1">
        <div className="text-center mb-4">
          <button onClick={togglePricing} className="btn btn-outline-secondary">
            {isYearly ? '🔄 Switch to Monthly' : '🔄 Switch to Yearly (Save 25%)🏷️'}
          </button>
        </div>
        <div className="row mb-3 text-center justify-content-center">
          <div className="col-12 col-md-6 mb-4">
            <div className="card box-shadow">
              <div className="card-header">
                <h4 className="my-0 font-weight-normal">Premium – {isYearly ? 'Yearly Plan' : 'Monthly Plan'}</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">
                  ${isYearly ? '29' : '39'} <small className="text-muted">/{isYearly ? 'mo' : 'mo'}</small>
                </h1>
                <p>{isYearly ? 'Billed annually 🎉' : 'Billed monthly'}</p>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>⭐️ Build in-demand technical skills</li>
                  <li>⭐️ Track your course progress</li>
                  <li>⭐️ Access entire course catalogue</li>
                  <li>⭐️ Access to upcoming courses</li>
                  <li>⭐️ Personalized Learning Schedule</li>
                  <li>⭐️ Intelligent Calendar Sync</li>
                  <li>⭐️ Learners Community Access</li>

                </ul>
                <a href={isYearly ? yearlyStripeLink : monthlyStripeLink  } type="button" className="btn btn-lg btn-block btn-primary">Get started →</a>
                {/* <hr/> */}
                {/* <p>Want to try it out first? <a href="/">Try a lesson free.</a> <b>No credit card required</b> to start.</p> */}
              </div>
            </div>
            {/* <div className="col-12 mt-4">
              <a className="pt-2" href="/">Why learn by doing?</a>
            </div> */}
          </div>
        </div>
      </div>
  )
};

export default Plan;
