import React from 'react';
import './LessonHeader.css';

const LessonHeader = ({ epic, title, description, dependencies, acceptanceCriteria }) => {
  return (
    <div className="lesson-header">
      <div className="epic-tag">{epic}</div>
      <h1 className="user-story">{title}</h1>
      <p className="description">{description}</p>
      {dependencies && dependencies.length > 0 && (
        <div className="tech-stack">
          <h3>Dependencies:</h3>
          <ul>
            {dependencies.map((tech, index) => (
              <li key={index}>{tech}</li>
            ))}
          </ul>
        </div>
      )}
      {acceptanceCriteria && acceptanceCriteria.length > 0 && (
        <div className="acceptance-criteria">
          <h3>Acceptance Criteria:</h3>
          <ul>
            {acceptanceCriteria.map((criteria, index) => (
              <li key={index}>🎯 {criteria}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default LessonHeader;
