// VideoContent.js
import React from 'react';
import ReactMarkdown from 'react-markdown';
import './videoContentStyles.css';

const VideoContent = ({ step }) => {
  const getEmbedUrl = (url) => {
    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      const videoId = url.split('v=')[1] || url.split('youtu.be/')[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (url.includes('loom.com')) {
      const videoId = url.split('share/')[1];
      return `https://www.loom.com/embed/${videoId}`;
    }
    return null;
  };

  const embedUrl = getEmbedUrl(step.url);

  if (!embedUrl) {
    return <div>Invalid video URL.</div>;
  }

  return (
    <div className="video-content mb-3 p-3 shadow-sm rounded">
      <div className="video-wrapper">
        <iframe
          src={embedUrl}
          title="Video"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
      {step.description && (
        <div className="description mt-3">
          <ReactMarkdown>{step.description}</ReactMarkdown>
        </div>
      )}
    </div>
  );
};

export default VideoContent;
