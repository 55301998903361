// ShellContent.js
import React, { useState, useEffect } from 'react';
import './shellContentStyles.css';
import { Button } from 'react-bootstrap';

const ShellContent = ({ step, onComplete, reset }) => {
  const [currentCommandIndex, setCurrentCommandIndex] = useState(0);
  const [showButton, setShowButton] = useState(true);

  const handleContinue = () => {
  if (currentCommandIndex < step.commandHistory.length - 1) {
    setCurrentCommandIndex(currentCommandIndex + 1);
  } else {
    // Reached the last command; proceed to the next step
    if (onComplete) {
      onComplete({ result: true });
    }
    // Hide the button after completion
    setCurrentCommandIndex(step.commandHistory.length);
  }
};

  // Show the button when at the initial command
  useEffect(() => {
    if (currentCommandIndex === 0) {
      setShowButton(true);
    }
  }, [currentCommandIndex]);

  useEffect(() => {
    // Reset the command index when reset prop changes
    setCurrentCommandIndex(0);
    setShowButton(true);
  }, [reset]);

  return (
    <div className="shell-content mb-3 p-3 shadow-sm rounded">
      <div className="shell-output">
        {step.commandHistory.slice(0, currentCommandIndex + 1).map((entry, index) => (
          <div key={index}>
            { entry.command === '' &&
              <>
                {entry.output && <div className="shell-response">{entry.output}</div>}
              </>
            }
            { entry.command !== '' &&
              <>
                <span className="shell-prompt">user@host:~$</span> {entry.command}
                {entry.output && <div className="shell-response">{entry.output}</div>}
              </>
            }
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-end mt-2">
        {showButton && currentCommandIndex < step.commandHistory.length && (
          <Button variant="warning" onClick={handleContinue}>
            {/* {currentCommandIndex < step.commandHistory.length - 1 ? 'Continue' : 'Quit'} */}
            Continue
          </Button>
        )}
      </div>
    </div>
  );
};

export default ShellContent;
