// CertificateContent.js
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
// import { supabase } from '../../../utils/supabase';
import './certificateContentStyles.css';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const CertificateContent = ({ step, session }) => {
  const [studentName, setStudentName] = useState('');
  const [missingInfo, setMissingInfo] = useState(false);

  useEffect(() => {
    const fetchUserName = async () => {
      // Uncomment the code below to fetch from Supabase
      /*
      if (!session || !session.user) return;

      const { data: userData, error } = await supabase
        .from('profiles')
        .select('first_name, last_name')
        .eq('id', session.user.id)
        .single();

      if (error || !userData) {
        setMissingInfo(true);
        return;
      }

      if (!userData.first_name || !userData.last_name) {
        setMissingInfo(true);
        return;
      }

      setStudentName(`${userData.first_name} ${userData.last_name}`);
      */

      // For testing without Supabase, use a default student name
      setStudentName('John Doe');
    };

    fetchUserName();
  }, [session]);

  const handleDownloadCertificate = () => {
    const input = document.getElementById('certificate');
    html2canvas(input, {
      scale: 2,
      useCORS: true, // Enable cross-origin images
      allowTaint: false,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('landscape', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('certificate.pdf');
    });
  };

  if (missingInfo) {
    return (
      <div className="certificate-content mb-3 p-3 shadow-sm rounded">
        <p>
          Please go to your <a href="/settings">settings</a> to update your first name and last name before downloading your certificate.
        </p>
      </div>
    );
  }

  return (
    <div className="certificate-content mb-3 p-3 shadow-sm rounded">
      <div id="certificate" className="certificate-template">
      <div className="row">
        <div className="col-6">
          {step.logo && (
            <img src={step.logo} alt="Logo" className="certificate-logo" />
          )}
          <h1>{step.title || 'Certificate of Completion'}</h1>
          <p>This certifies that</p>
          <h2>{studentName}</h2>
          <p>has successfully completed the course</p>
          <h3>{step.courseName}</h3>
        </div>
        <div className="col-6">
          <div className="col-12">
              <p>on {new Date().toLocaleDateString()}</p>
            {step.signature && (
              <div className="signature">
                <img src={step.signature} alt="Signature" />
                <p>{step.signatureTitle}</p>
              </div>
            )}
          </div>
          <div className="col-12">
            {step.courseCompleteImage && (
              <img src={step.courseCompleteImage} alt="Course Complete" className="course-complete-image" />
            )}
          </div>
        </div>
        </div>

      </div>
      <Button variant="success" className="mt-3" onClick={handleDownloadCertificate}>
        Download Certificate
      </Button>
    </div>
  );
};

export default CertificateContent;
