// InputHTMLContent.js
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import DOMPurify from 'dompurify';
import Prism from 'prismjs';
import 'prismjs/components/prism-markup'; // For HTML
import 'prismjs/components/prism-css';
import 'prismjs/themes/prism.css'; // Or use your preferred theme
import Editor from 'react-simple-code-editor';
import './inputHTMLContentStyles.css';
import ReactMarkdown from 'react-markdown';
import { diffLines } from 'diff'; // Import diffLines from 'diff' library

const InputHTMLContent = ({
  step,
  onComplete,
  session,
  courseId,
  lessonId,
  levelId,
  stepIndex,
  reset,
  currentStepIndex,
}) => {
  const [inputValue, setInputValue] = useState(step.initialValue || '');
  const [errorMessage, setErrorMessage] = useState('');
  const [hasPassed, setHasPassed] = useState(false);
  const [showCheckmark, setShowCheckmark] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);

  // Hint feature state variables
  const [hintCount, setHintCount] = useState(0);
  const [isHintActive, setIsHintActive] = useState(false);
  const [diffs, setDiffs] = useState([]); // To store diff objects
  const [revealedDiffsCount, setRevealedDiffsCount] = useState(0);

  // New state to track the current step index
  const [currentStep, setCurrentStep] = useState(stepIndex);

  useEffect(() => {
    // console.log('InputHTMLContent reset triggered');
    handleReset();
  }, [reset]);

  useEffect(() => {
    // If the step has changed, disable input
    if (currentStep !== stepIndex) {
      setHasPassed(true);
      setShowSubmitButton(false);
    }
  }, [stepIndex, currentStep]);

  useEffect(() => {
    // Compute the diffs between initialValue and expectedResult using diffLines
    const computeDiffs = () => {
      const initialValue = step.initialValue || '';
      const expectedResult = Array.isArray(step.expectedResult)
        ? step.expectedResult[0]
        : step.expectedResult;

      const diffsResult = diffLines(initialValue, expectedResult);
      setDiffs(diffsResult);
      setRevealedDiffsCount(0);
      setHintCount(0);
    };

    computeDiffs();
    setCurrentStep(stepIndex); // Update the current step index
  }, [step.initialValue, step.expectedResult, stepIndex]);

  const highlight = (code) =>
    Prism.highlight(code, Prism.languages.html, 'html');

  const handleInputChange = (code) => {
    setInputValue(code);
    setErrorMessage('');
    setShowCheckmark(false);
    setIsHintActive(false);
    checkInputMatch(code);
  };

  const handleSubmit = () => {
    if (showCheckmark) {
      setHasPassed(true);
      setShowSubmitButton(false);
      onComplete({ input: inputValue, result: true });
    } else {
      const error = getErrorMessage();
      setErrorMessage(error);
    }
  };

  const checkInputMatch = (value) => {
    const normalizeHtml = (html) => {
      return DOMPurify.sanitize(html.trim())
        .replace(/\s+/g, ' ')       // Replace multiple whitespace with single space
        .replace(/> </g, '><')      // Remove spaces between tags
        .replace(/\n/g, '')         // Remove newlines
        .replace(/\r/g, '');        // Remove carriage returns
    };

    const normalizedInput = normalizeHtml(value);
    const expectedResults = Array.isArray(step.expectedResult)
      ? step.expectedResult
      : [step.expectedResult];

    const isMatch = expectedResults.some((expected) => {
      const normalizedExpected = normalizeHtml(expected);
      return normalizedInput === normalizedExpected;
    });

    setShowCheckmark(isMatch);
  };

  const getErrorMessage = () => {
    const errorMessages = step.errorMessages || {};
    return errorMessages[''] || 'Incorrect. Try again.';
  };

  const handleHint = () => {
    const initialValue = step.initialValue || '';
    const expectedResult = Array.isArray(step.expectedResult)
      ? step.expectedResult[0]
      : step.expectedResult;

    const totalDiffs = diffs.filter((part) => part.added).length;

    if (revealedDiffsCount < totalDiffs) {
      // Reveal one more diff with each hint
      const newRevealedDiffsCount = revealedDiffsCount + 1;
      setRevealedDiffsCount(newRevealedDiffsCount);

      // Build the new inputValue with revealed diffs
      let revealedDiffs = 0;
      let newValue = '';
      diffs.forEach((part) => {
        if (part.added) {
          if (revealedDiffs < newRevealedDiffsCount) {
            // Reveal this added part
            newValue += part.value;
            revealedDiffs++;
          } else {
            // Skip this part (not yet revealed)
          }
        } else if (part.removed) {
          // Skip removed parts
        } else {
          // Unchanged part
          newValue += part.value;
        }
      });

      setInputValue(newValue);
      // Call checkInputMatch with the updated inputValue
      checkInputMatch(newValue);
      // Clear any existing error messages
      setErrorMessage('');
    } else {
      // All diffs have been revealed; show the full expected result
      setInputValue(expectedResult);
      // Call checkInputMatch with the expectedResult
      checkInputMatch(expectedResult);
      // Clear any existing error messages
      setErrorMessage('');
    }

    setIsHintActive(true);
    setTimeout(() => {
      setIsHintActive(false);
    }, 500); // Pulsing effect lasts for 0.5 seconds (adjust as needed)

    setHintCount(hintCount + 1); // Increment hint count
    };


  const handleReset = () => {
    setInputValue(step.initialValue || '');
    setErrorMessage('');
    setHasPassed(false);
    setShowCheckmark(false);
    setShowSubmitButton(true);
    setHintCount(0);
    setIsHintActive(false);
    setRevealedDiffsCount(0);
    Prism.highlightAll();
  };

  const PassIcon = () => (
    <svg
      aria-label="Pass Icon"
      aria-hidden="false"
      className="ml-2"
      height="24"
      role="img"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Pass Icon</title>
      <circle cx="12" cy="12" r="12" fill="#4CAF50" />
      <path
        d="M8.75 17.882l-4.359-4.359a.75.75 0 0 1 1.06-1.06l4.006 4.005 9.005-9.006a.75.75 0 0 1 1.061 1.061l-9.359 9.359a1 1 0 0 1-1.414 0z"
        fill="#FFF"
      />
    </svg>
  );

  return (
    <div className="input-html-content mb-3 p-3">
      <div>
        <ReactMarkdown>{step.content}</ReactMarkdown>
      </div>
      {(!hasPassed || !showSubmitButton) && step.description && (
        <div className="step-description">
          <ReactMarkdown>{step.description}</ReactMarkdown>
        </div>
      )}
      <div className="live-output mt-3">
        <p className="output-label">Live Output:</p>
        <div
          className="output-render"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(inputValue),
          }}
        ></div>
      </div>
      {showCheckmark && (
        <div className="checkmark">
          {/* SVG Blue Checkmark */}
          <svg width="18" height="18" viewBox="0 0 24 24">
            <circle cx="12" cy="12" r="12" fill="#2196F3" />
            <path
              d="M17 7l-8.5 8.5L7 13"
              stroke="#fff"
              strokeWidth="2"
              fill="none"
            />
          </svg>
          <span className="ml-2" style={{fontSize:'15px'}}>You've matched the expected result.</span>
        </div>
      )}
      <div className="code-editor mt-3">
        <Editor
          data-record="true"
          value={inputValue}
          onValueChange={handleInputChange}
          highlight={highlight}
          padding={10}
          textareaClassName={`code-input ${
            isHintActive ? 'pulse-animation' : ''
          }`}
          preClassName="language-html"
          disabled={hasPassed} // Disable input when the step is completed
          style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 16,
            minHeight: '200px',
            backgroundColor: hasPassed ? '#e9ecef' : '#f5f2f0', // Change background when disabled
          }}
        />
      </div>
      {errorMessage && <div className="mt-2 text-danger">{errorMessage}</div>}
      {showSubmitButton && !hasPassed && (
        <div className="d-flex justify-content-end mt-2">
          <Button
            variant="light"
            className="me-2 border"
            onClick={() => {
              handleReset();
              posthog.capture('Clicked Reset Challenge', {
                contentType: 'inputHTMLContent',
                stepIndex: stepIndex,
                lessonId: lessonId,
                levelId: levelId, // Assuming levelId is part of the step object
                courseId: courseId,
              });
            }}
          >
            Reset Challenge
          </Button>
          <Button
            variant="light"
            className="me-2 border"
            onClick={() => {
              handleHint();
              posthog.capture('Clicked Hint', {
                contentType: 'inputHTMLContent',
                stepIndex: stepIndex,
                lessonId: lessonId,
                levelId: levelId, // Assuming levelId is part of the step object
                courseId: courseId,
              });
            }}
            disabled={revealedDiffsCount >= diffs.filter((part) => part.added).length}
          >
            {revealedDiffsCount < diffs.filter((part) => part.added).length ? 'Hint' : 'No more hints'}
          </Button>
          <Button variant="warning" onClick={handleSubmit}>
            Submit Answer
          </Button>
        </div>
      )}
      {hasPassed && (
        <div className="mt-3 text-success">
          <Button
            variant="light"
            className="me-2 border"
            onClick={() => {
              handleReset();
              posthog.capture('Clicked Reset Challenge', {
                contentType: 'inputHTMLContent',
                stepIndex: stepIndex,
                lessonId: lessonId,
                levelId: levelId, // Assuming levelId is part of the step object
                courseId: courseId,
              });
            }}
          >
            Reset Challenge
          </Button>
          <PassIcon className="justify-content-end" />
        </div>
      )}
    </div>
  );
};

export default InputHTMLContent;
